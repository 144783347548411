import React, { useContext, useState, useEffect, useCallback } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import ReactLoader from '../components/ReactLoader';
import { LoadingContext } from '../context/LoadingContext';
import Swal from 'sweetalert2'

const config = require('../config.json')

export default function Request({ ipAddress, loginData }) {
  const [balance, SetBalance] = useState(0.0)
  const [loading, setLoading] = useContext(LoadingContext)
  const [info, setInfo] = useState({})
  const [isPay, setispay] = useState(0);
  const handleWallet = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "ip": ipAddress,
      "ulid": loginData.ulid
    });

    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}wallet`,
      headers: {
        'ip': ipAddress,
        'ulid': loginData.ulid,
        'auth': loginData.auth,
        'token': loginData.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)
      .then((response) => {
        console.log(response.data)
        SetBalance(response.data?.balance)
        setInfo(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  useEffect(() => {

    handleWallet()
  }, [handleWallet])

  return (
    <>
      <div className="layout-container">
        <Menu />
        <div className="layout-page">
          <Header />
          {loading ? <><ReactLoader/></> :
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y pt-2">
              <div className='row'>
                <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                        <div className="card-body dashinc">
                            <div className="d-flex justify-content-between">
                                <div className="card-info">
                                    <p className="card-text m-0 text-black text-sm">Wallet Balance </p>
                                    <div className="d-flex align-items-end mb-2">
                                        <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {info?.balance}  </small>
                                    </div>
                                    
                                </div>
                                <div className="card-icon">
                                    <span className="badge bg-label-warning rounded p-2">
                                        <i className='bx bx-rupee bx-sm'></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                    <div className="card">
                        <div className="card-body dashinc">
                            <div className="d-flex justify-content-between">
                                <div className="card-info">
                                    <p className="card-text m-0 text-black text-sm">Total Withdrawal </p>
                                    <div className="d-flex align-items-end mb-2">
                                        <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {info?.withdrawal_paid}  </small>
                                    </div>
                                </div>
                                <div className="card-icon">
                                    <span className="badge bg-label-warning rounded p-2">
                                        <i className='bx bx-rupee bx-sm'></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className='row'>
                
                <div className='col-md-12'>
                    <div class="card">
                        <p class="card-header" style={{fontSize:"11px"}}>Payout Information</p>
                        <div class="card-datatable table-responsive text-center">
                            <table class="datatable table border-top table-sm table-hover  table-striped text-center" style={{fontSize : "10px"}}>
                                <thead >
                                    <tr>
                                        <th style={{fontSize:"9px"}}>Date</th>
                                        <th style={{fontSize:"9px"}}>Amount</th>
                                        <th style={{fontSize:"9px"}}>Tax</th>
                                        <th style={{fontSize:"9px"}}>Net Amount</th>
                                        <th style={{fontSize:"9px"}}>Status</th>
                                        <th style={{fontSize:"9px"}}>Details</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    info.list?.map((list, index)=>{
                                        
                                        return ( 
                                                <>
                                                    <tr id={index}>
                                                        <td>{list.rdate}</td>
                                                        <td>{list.amount}</td>
                                                        <td>{list.tax}</td>
                                                        <td>{list.netAmont}</td>
                                                        <td>{list.status}</td>
                                                        <td>{list.detail}</td>
                                                    </tr>
                                                </>      
                                        )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
          </div>
          }
        </div>
      </div>
    </>
  );
}

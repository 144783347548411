import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';

import Swal from 'sweetalert2'
const config = require('../config.json')
export default function Package({ ipAddress, loginData }) {
    
    const [wallet, setWallet] = useState('')
    const [plid, setPuid] = useState('')
    const [pid, setPid] = useState('')
    const [tran, setTran] = useState('')
    const [rebuy, setRebuy] = useState(0)
    const [bal, setBal] = useState(0)
    const [canb, setCanb] = useState(false)
    const [cans, setCans] = useState(false)
    const [img, setImg] = useState(null)
    const sendToken = async (p, e) => {
        if(1)
        {
            Swal.fire({title : "Multi Store Markiting",
            icon : 'info',
            text : "Do you Want to Buy Package of "+e, 
            showDenyButton: true,
            confirmButtonText: "Yes Buy Now",
            denyButtonText: `Don't Buy`
            }).then((res) => {
                if (res.isConfirmed) {
                    setCans(true)
                    setPid(p)
                    setPuid(e)
                }
                
            })
        }
        else
        {
            Swal.fire({
                title : "Multi Store Markiting",
                icon : 'info',
                text : "Please click again."
            })
        }
    }
    const handleLeverage = () => {
        console.log(img)
        if(img === null)
        {
            Swal.fire('Multi Store Markiting', "Please Select Screen Shot for transaction", 'warning')
            return
        }
        
        let data = JSON.stringify({
            "ip": ipAddress,
            "amt": plid,
            "packId" : pid,
            "img"   : img,
            "tran"  : tran
        });
        Swal.fire('Multi Store Markiting', "Please wait for geting Information", 'info')
        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}/buy`,
            headers: {
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                console.log(response.data);
                let  res = response.data;
                    Swal.fire({
                        title : "Multi Store Markiting",
                        icon : 'info',
                        text : res?.message
                    })
                    handleWallet()
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleWallet = useCallback(() => {

        let data = JSON.stringify({
            "ip": ipAddress,
            "ulid": loginData.ulid
        });

        let axiosConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.baseUrl}packageonly`,
            headers: {
                'ip': ipAddress,
                'ulid': loginData.ulid,
                'auth': loginData.auth,
                'token': loginData.token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(axiosConfig)
            .then((response) => {
                setWallet(response.data)
                setBal(response.data?.balance)
                setCanb(response.data?.canb)
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [ ipAddress, loginData])
    useEffect(()=>{
    handleWallet()
      },[handleWallet])

     const getBase64 = file => {
        return new Promise(resolve => {
          //let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL);
          };
          //console.log(fileInfo);
        });
      };
    
    return (
        <>
            <div className="layout-container">
                <Menu />
                <div className="layout-page">
                    <Header />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y pt-2">
                            <div>
                                {(canb)? 
                                <>
                                {(cans)? <>
                                    <div className='row'>
                                        <div className="col-md-12  mb-2">
                                            <div className="card">
                                                <div className="card-header align-items-center ">
                                                    <div className="card-title mb-0">
                                                        <h6 className="m-0 me-2 text-center text-black mb-2">Upload Image of Transaction </h6>
                                                    </div>
                                                </div>
                                                <div className="card-body align-items-center p-3">
                                                    <form id="formAuthentication" className="mb-3" >
                                                        <div className="mb-3">
                                                                <label htmlFor="1email" className="form-label">Transaction Number</label>
                                                                <input type="text" className="form-control"  placeholder="Enter User ID"  onChange={(e) => setTran(e.target.value)} />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="e1mail" className="form-label">Amount</label>
                                                                <input type="text" className="form-control"  placeholder="Enter your name" value={plid} readOnly  />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="e1mail" className="form-label">Select Image</label>
                                                                <input type='file' className='form-controle' accept="image/*"  id="mobile" name="mobile" placeholder="Contact Number"  onChange={event =>getBase64(event.target.files[0]).then(file =>setImg(file))}/>
                                                            </div>
                                                    </form>
                                                    <div className='text-center mt-0'>
                                                        <button className='btn  btn-secondary btn-sm' onClick={() => handleLeverage()}>Upload Now</button>
                                                    </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </>:<>
                                    <div className='row'>
                                        <div className="col-md-12  mb-2">
                                            <div className="card">
                                                <div className="card-header align-items-center ">
                                                    <div className="card-title mb-0">
                                                        <h6 className="m-0 me-2 text-center text-black mb-2">Buy Package </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {wallet?.packages?.map((list, i)=> {
                                            return (
                                                <>
                                                    <div className="col-md-6  mb-1" id={i+1} key={i}>
                                                        <div className="card ">
                                                            
                                                            <div className="card-body align-items-center p-3">
                                                                <div className="card-title mb-0">
                                                                    <h5 className="m-0 me-2 text-center text-black mb-2">{list.package_name}</h5>
                                                                    <h6 className="m-0 me-2 text-center text-black mb-2"><i className='bx bx-rupee bx-xs'></i> {list.pamt} (<i className='bx bx-coin-stack bx-sm'></i> {list.point})</h6>
                                                                </div>
                                                                <div className='text-center mt-0'>
                                                                    <button className='btn  btn-secondary btn-sm' onClick={() => sendToken(list.id, list.pamt)}>Buy {list.package_name}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </>}
                                </> 
                                
                                : <>
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                            <div className="card-header align-items-center ">
                                                <div className="card-title mb-0">
                                                    <h6 className="m-0 me-2 text-center text-black mb-2">Dear User You can't Buy package Again Please Contact Admin for this </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></>}
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card h-100">
                                            <div className="card-header align-items-center" style={{padding :"3% 5% 3% 5%"}}>
                                            { wallet?.req?.map((list, i) =>{
                                                // console.log(list)
                                                let st = 'Processing'
                                                if(list?.is_paid === 1)
                                                {
                                                    st = 'complete';
                                                }
                                                else if(list?.is_paid === 2)
                                                {
                                                    st = "Cancled"    
                                                }
                                                return(
                                                <div className="col-md-12">
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-6 text-left'>
                                                            <span className="text-black text-sm">Request Date</span>
                                                        </div>
                                                        <div className='col-6' style={{textAlignLast:"end"}}>
                                                            <span className={'text-black text-sm'} style={{fontSize:"14px"}}>{list?.paid_dt}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-6 text-left'>
                                                            <small className="text-sm text-black">Package </small>
                                                        </div>
                                                        <div className='col-6 ' style={{textAlignLast:"end"}}>
                                                            <small className="text-sm text-black">{list?.pname}</small>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-6 text-left'>
                                                            <small className="text-sm text-black">Amount  </small>
                                                        </div>
                                                        <div className='col-6 ' style={{textAlignLast:"end"}}>
                                                            <small className="text-sm text-black">{list?.amt} </small>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-6 text-left'>
                                                            <small className="text-sm text-black">Status </small>
                                                        </div>
                                                        <div className='col-6' style={{textAlignLast:"end"}}>
                                                            <small className="text-sm text-black">{st}</small>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-6 text-left'>
                                                            <small className="text-sm">Date of Response</small>
                                                        </div>
                                                        <div className='col-6' style={{textAlignLast:"end"}}>
                                                        <small className="text-sm text-black">{list?.cp_date}</small>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-between'>
                                                        <div className='col-12 text-center'>
                                                            <img src={list?.up_img} alt='Transaction clip' width={"200px"} height={"auto"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                )} ) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { MobileSidebarContext } from '../context/MobileSidebarContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const config = require('../config.json')



export default function Header() {
    const [account, setAccount] = useState('');
    const [uid, setuid] = useState('');
    const history = useHistory();
    const [ipAddress] = useState("1.1.1.1");
    const [mobileOpen, setMobileOpen] = useContext(MobileSidebarContext)
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const handlePrice = useCallback(async () => {
        if(loginData?.uid?.length < 7)
        {
            sessionStorage.removeItem('loginData');
            history.push('/');
            // console.log(uid)
        }
    }, [history, loginData]);
    useEffect(() => {
        
            setAccount((loginData?.address) ? loginData?.address : '')
            setuid((loginData?.ulid) ? loginData?.ulid : '')
            // console.log(loginData)
            handlePrice()
    }, [handlePrice, loginData]);
    const handleLogout = useCallback(async () => {
       
        try {
            
            let data = JSON.stringify({
                "address": (loginData?.address) ? loginData?.address : account,
                "ip": ipAddress,
                "ulid": loginData?.ulid
            });

            let axiosConfig = {
                method: 'post',
                url: `${config.baseUrl}logout`,
                headers: {
                    'address': account,
                    'ip': ipAddress,
                    'ulid': loginData?.ulid,
                    'auth': loginData?.auth,
                    'token': loginData?.token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            let response = await axios.request(axiosConfig)
            // response = response.data
            //   console.log('logging out')
            // console.log(response)
            sessionStorage.removeItem('loginData');
            history.push('/');

        } catch (error) {
            console.error(error)
        }
    }, [account, history, ipAddress, loginData]);
    return (
        <>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
                    <span onClick={() => setMobileOpen(true)} className="nav-item nav-link px-0 me-xl-4 " style={{ zIndex: 9000 }}>
                        <i className="bx bx-menu bx-sm" />
                    </span >
                </div>
                
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        {/* Style Switcher */}
                        <li className="nav-item me-2 me-xl-0">
                            <span className="nav-link style-switcher-toggle hide-arrow">
                                <i className="bx bx-sm" />
                            </span>
                        </li>
                        {/*/ Style Switcher */}
                        {/* Quick links  */}
                        
                        <li className="nav-item ms-1 me-xl-0">
                            <span className="btn btn-warning text-nowrap btn-xs text-sm">
                                {/* <i className="bx bx-user-check me-1" /> */}
                                {(loginData?.ulid)}
                            </span>
                        </li>
                        <li className="nav-item ms-1 me-xl-0">
                            <Link onClick={() => handleLogout()} to="/" className="menu-link text-mid"  data-toggle="tooltip" data-placement="bottom" title="Disconnect">
                                <i className="menu-icon tf-icons bx bx-exit" style={{ marginLeft: '0.5rem', color: "black" }} />
                            </Link>
                        </li>

                        {/* <li className="nav-item navbar-dropdown dropdown-user dropdown">
							    <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
							      <div className="avatar avatar-online">
							        <img src="assets/img/avatars/1.png" alt className="w-px-40 h-auto rounded-circle"/>
							      </div>
							    </a>
							    <ul className="dropdown-menu dropdown-menu-end">
							      <li>
							        <a className="dropdown-item" href="login.php" target="_blank">
							          <i className="bx bx-power-off me-2"></i>
							          <span className="align-middle">Log Out</span>
							        </a>
							      </li>
							    </ul>
							  </li> */}
                    </ul>
                </div>
            </nav>
        </>
    )
}

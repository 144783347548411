import React, { useCallback, useContext, useEffect,useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import axios from 'axios';
import { LoadingContext } from '../context/LoadingContext';
import ReactLoader from '../components/ReactLoader';
const config = require('../config.json')

export default function Business({ipAddress, loginData}) {
    const [account, setAccount] = useState(loginData?.address);
  const [business,setBusiness] = useState({});
  const [loading, setLoading] = useContext(LoadingContext)
  const [businessTable,setBusinessTable] = useState({});
  const [mid ,  setOld] = useState(0);
  const handleBusiness = useCallback(() => {
    setLoading(true)
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/income`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        setBusiness(response.data)
        console.log(response.data)
      setBusinessTable(response.data)
    //   console.log(response.data); 
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
    });
  },[account, ipAddress, loginData.auth, loginData.token, loginData.ulid, setLoading])

  const showmore = ((e) => {
    e.preventDefault()
    
    let data = JSON.stringify({
      "address": account,
      "ip": ipAddress,
      "ulid": loginData.ulid,
      'mid'    : mid
    });
    
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}/incomes`,
      headers: { 
        'address': account, 
        'ip': ipAddress, 
        'ulid': loginData.ulid, 
        'auth': loginData.auth, 
        'token': loginData.token, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    // console.log(axiosConfig)
    axios.request(axiosConfig)  
    .then((response) => {
        console.log(response.data)
      setBusinessTable(response.data)
    
    })
    .catch((error) => {
      console.log(error);
    });
  })

  useEffect(() => {
    
    handleBusiness()
  },[handleBusiness])

  
    return (
        <>
                <div className="layout-container">
                    <Menu />
                    <div className="layout-page">
                        <Header />
                        {loading ? <><ReactLoader/></> :
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y pt-2">
                                <div className='row'>
                                    <div className="col-md-12  mb-2">
                                        <div className="card">
                                        <div className="card-header align-items-center ">
                                            <div className="card-title mb-0">
                                            <h6 className="m-0 me-2 text-center text-black">Income List</h6>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Total Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i> {business?.dash?.toinc}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Today Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i> {business?.dash?.tdinc} </small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2">
                                        <div className="card" >
                                            <div className="card-body dashinc" >
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">Yesterday Income</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i> {business?.dash?.ysinc}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon">
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2" >
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">{business?.main?.reffm?.name}</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {business?.main?.reff}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon" >
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2" >
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">{business?.main?.levelm?.name}</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {business?.main?.level}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card-icon" >
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6 mb-2" >
                                        <div className="card">
                                            <div className="card-body dashinc">
                                                <div className="d-flex justify-content-between">
                                                    <div className="card-info">
                                                        <p className="card-text m-0 text-sm">{business?.main?.clubm?.name}</p>
                                                        <div className="d-flex align-items-end mb-2">
                                                            <small className="text-black "><i className='bx bx-rupee bx-xs'></i>  {business?.main?.club}</small>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="card-icon" >
                                                        <span className="badge bg-label-warning rounded p-2">
                                                            <i className='bx bx-rupee bx-sm'></i>
                                                        </span>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                                <div className='row'>
                                <div className='col-md-12'>
                                        <div className="card mb-4">
                                            <h5 className="card-header text-center text-black">Update Password</h5>
                                            <div className="card-body">
                                                <form id="formAccountSettings2" onSubmit={showmore} className="fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate">
                                                    <div className="row">
                                                        <div className="mb-3 col-sm-6 fv-plugins-icon-container">
                                                            <label htmlFor="opass" className="form-label">Select Income </label>
                                                            <select id="opass" name="companyName" className="form-control" onChange={(e) => {setOld(e.target.value)}}>
                                                                {
                                                                    business?.type?.map( (item, index) => {
                                                                        
                                                                        return (<option key={item?.mIndex} value={item?.mIndex}>{item?.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <div className="fv-plugins-message-container invalid-feedback" />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 text-center">
                                                        <button type="submit" className="btn btn-secondary me-2 text-sm">Get Income Details</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <div className="card">
                                        <p className="card-header">Income Information</p>
                                        <div className="card-datatable table-responsive text-center">
                                            <table className="datatable table border-top table-sm table-hover table-bordered table-striped text-center" style={{fontSize : "10px"}}>
                                                <thead >
                                                    <tr>
                                                        <th style={{fontSize:"11px"}}>Type of Income</th>
                                                        <th style={{fontSize:"11px"}}>Income Level</th>
                                                        <th style={{fontSize:"11px"}}>Level</th>
                                                        <th style={{fontSize:"11px"}} >Amount</th>
                                                        <th style={{fontSize:"11px"}}>Date</th>
                                                        <th style={{fontSize:"11px"}}>Refrence</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    businessTable?.data?.map((list, index)=>{
                                                        
                                                        return ( 
                                                                <>
                                                                    <tr id={index}>
                                                                        <td>{list.type}</td>
                                                                        <td>{list.income_on}</td>
                                                                        <td>{list.income_level}</td>
                                                                        <td>{list.income_amount}</td>
                                                                        <td>{list.dt}</td>
                                                                        <td>{list.refrence}</td>
                                                                    </tr>
                                                                </>      
                                                        )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    }
                    </div>
                </div>
        </>
    );
}
